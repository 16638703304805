import { lazyWithRetry, PrivateRoute } from 'modules/routing';
const Console = lazyWithRetry(() => import('./index'));

export const consoleRoutes = [
  {
    path: '/dashboard/console',
    name: 'Console',
    component: Console,
    route: PrivateRoute,
  },
];
