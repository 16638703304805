import Big, { BigSource } from 'big.js';
import { isNaN, isNil } from 'lodash';
import prettyNum, { PRECISION_SETTING, ROUNDING_MODE } from 'pretty-num';

export function formatWithAutoPrecision(
  number: number,
  precisionSettingInput?: number
) {
  if (isNil(number) || isNaN(number)) return '';
  const precision =
    number > 1000 ? 1 : number > 100 ? 2 : number > 3 ? 2 : number > 1 ? 3 : 6;
  const precisionSetting =
    precisionSettingInput ??
    (number < 1
      ? PRECISION_SETTING.REDUCE_SIGNIFICANT
      : PRECISION_SETTING.FIXED);

  return prettyNum(number, {
    precision,
    precisionSetting,
    roundingMode: ROUNDING_MODE.DOWN,
    thousandsSeparator: ',',
  });
}

export function formatWithPrecision(
  precision: number,
  number: number,
  precisionSettingInput?: number
) {
  if (isNil(number) || isNaN(number)) return '';

  const precisionSetting =
    precisionSettingInput ??
    (number < 1
      ? PRECISION_SETTING.REDUCE_SIGNIFICANT
      : PRECISION_SETTING.FIXED);

  return prettyNum(number, {
    precision,
    precisionSetting,
    roundingMode: ROUNDING_MODE.DOWN,
    thousandsSeparator: ',',
  });
}

export const formatTokenAmount = (num: number) => formatWithPrecision(3, num);
export const formatWithoutDecimals = (num: number) =>
  formatWithPrecision(0, num);

export const formatTokenAmountCompact = (num: number) =>
  formatWithPrecision(2, num, num % 1 === 0 ? 0 : undefined);

export const formatMzrAmount = (num: number | string) => {
  if (isNil(num) || isNaN(+num)) return '';

  return prettyNum(Big(num).toNumber(), {
    precision: 1,
    precisionSetting: PRECISION_SETTING.REDUCE_SIGNIFICANT,
    roundingMode: ROUNDING_MODE.DOWN,
    thousandsSeparator: ',',
  });
};

export const formatUSDAmount = (num: number | string) => {
  if (isNil(num) || isNaN(num)) return '';

  return prettyNum(Big(num).toNumber(), {
    precision: 2,
    precisionSetting: PRECISION_SETTING.FIXED,
    roundingMode: ROUNDING_MODE.DOWN,
    thousandsSeparator: ',',
  });
};

export const formatUSDAmountWithSign = (
  num: number | string,
  precision?: number
) => {
  if (isNil(num) || isNaN(num)) return '';

  const sign = Big(num).gt(0) ? '+' : Big(num).eq(0) ? '' : '-';
  const number = prettyNum(
    Big(num)
      .mul(sign === '-' ? -1 : 1)
      .toNumber(),
    {
      precision: precision ?? 2,
      precisionSetting: PRECISION_SETTING.FIXED,
      roundingMode: ROUNDING_MODE.DOWN,
      thousandsSeparator: ',',
    }
  );
  return sign + '$' + number;
};

export const formatPercentageWithSign = (
  num: number | string,
  precision?: number
) => {
  if (isNil(num) || isNaN(num)) return '';

  const sign = Big(num).gt(0) ? '+' : '';
  const number = prettyNum(Big(num).toNumber(), {
    precision: precision ?? 2,
    precisionSetting: PRECISION_SETTING.FIXED,
    roundingMode: ROUNDING_MODE.DOWN,
    thousandsSeparator: ',',
  });
  return sign + number + '%';
};

export function valueAsSafeNumber(val: string | undefined) {
  if (isNil(val) || val === '' || isNaN(+val)) {
    return undefined;
  }

  return Big(val).round(10, Big.roundDown).toNumber();
}

const defaultSuffixes = ['', 'K', 'M', 'B', 'T', 'Q', 'Qu', 'S', 'O', 'N', 'D']; // Add more suffixes as needed
export const allSuffixes = [
  '',
  'K',
  'M',
  'B',
  'T',
  'Q',
  'Qu',
  'S',
  'O',
  'N',
  'D',
];

type FormatOptions = {
  suffixes?: string[];
  precision?: number;
};

export function formatNumberWithSuffix(
  number: BigSource,
  { suffixes = defaultSuffixes, precision = 4 }: FormatOptions = {}
): string {
  const nr = Big(number);
  if (nr.eq(0)) return '0';

  return nr.abs().gte(1)
    ? formatLargeNumber(nr.toNumber(), { precision, suffixes })
    : formatSmallNumber(nr.toNumber(), { precision, suffixes });
}

function formatLargeNumber(
  number: number,
  { suffixes, precision }: Required<FormatOptions>
) {
  const numAbs = Math.abs(number);
  const sign = Math.sign(number);
  let formattedNumber = formatWithPrecision(precision, numAbs);

  const suffixIndex = Math.floor(Math.log10(numAbs) / 3);
  const suffix = suffixes[suffixIndex];

  if (suffix) {
    const divisor = Math.pow(10, suffixIndex * 3);
    const abbreviatedNumber = numAbs / divisor;
    formattedNumber = abbreviatedNumber.toFixed(1);
  }

  return `${sign === -1 ? '-' : ''}${formattedNumber}${suffix || ''}`;
}

const normalToSubscript = {
  0: '₀',
  '1': '₁',
  '2': '₂',
  '3': '₃',
  '4': '₄',
  '5': '₅',
  '6': '₆',
  '7': '₇',
  '8': '₈',
  '9': '₉',
};

export const subscriptNumbers = Object.values(normalToSubscript);

function formatSmallNumber(
  number: number,
  { precision }: Required<FormatOptions>
): string {
  const numberString = Big(number).toFixed(21);
  let index = numberString.indexOf('.') + 1;
  while (numberString[index] === '0') {
    index++;
  }

  const base = index - numberString.indexOf('.') - 1;

  if (base <= 3) {
    return formatWithPrecision(precision, number);
  }

  const baseSubscript = [...base.toString()]
    .map((char) => normalToSubscript[char as keyof typeof normalToSubscript])
    .join('');
  const numberSubscript = [...numberString.slice(index)].join('');

  const nr = Big(`0.${numberSubscript}`)
    .toFixed(precision, Big.roundDown)
    .toString()
    .slice(2);
  const newNotation = `0.0${baseSubscript}${nr}`;

  return newNotation;
}
