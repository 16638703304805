import { RadioGroup, RadioGroupProps } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { BaseButton } from '../button/base-button';

type Props = RadioGroupProps<'div', string> & {
  options: { name: string; value: string }[];
};

export function ToggleButton({ onChange, value, options, ...rest }: Props) {
  return (
    <RadioGroup value={value} onChange={onChange} {...rest}>
      <div className="flex flex-row xxs:border-black-100 dark:border-black-600 xxs:border-solid xxs:border xxs:rounded">
        {options.map((option, i) => (
          <RadioGroup.Option
            key={i}
            value={option.value || null}
            as={BaseButton}
            className={({ active, checked }) =>
              twMerge(
                'xxs:px-3 xxs:py-2 xxs:text-sm',
                active ? '' : '',
                checked
                  ? 'xxs:bg-white-500 dark:bg-black-600 xxs:font-semibold'
                  : 'xxs:font-normal',
                ''
              )
            }
          >
            <RadioGroup.Label as="span">{option.name}</RadioGroup.Label>
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
}
