import './assets/scss/Saas.scss';
import './assets/css/tailwind.css';
import * as moment from 'moment';
import 'moment/min/locales';
import Maintenance from 'utils/Maintenance';

const AppMaintenance = () => {
  const locale = window.navigator.language;
  moment.locale(locale);
  return <Maintenance />;
};

export default AppMaintenance;
