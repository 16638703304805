import { Disclosure } from '@headlessui/react';
import { ChevronRightIcon } from '@heroicons/react/24/solid';
import classNames from 'classnames';
import { cexNavigation } from 'modules/layouts/TopBar/config';
import Tag from 'modules/shared-components/data-display/tag';
import { FC, ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

interface ExpandableItemProps {
  name: string;
  icon?: ReactNode;
  isActive?: boolean;
  closeNav?: () => void;
  subMenus: (typeof cexNavigation)[number]['subMenus'];
  tag?: string;
}

const ExpandableItem: FC<ExpandableItemProps> = ({
  icon,
  name,
  isActive,
  closeNav,
  subMenus,
  tag,
}) => {
  return (
    <Disclosure as="div" className="w-full" defaultOpen={isActive}>
      {({ open }) => (
        <>
          <Disclosure.Button
            as="div"
            data-testid={`expandable-${name}`}
            className={classNames(
              'dark:text-gray-300 hover:bg-blue-100 dark:hover:bg-blue-900',
              'block xxs:px-3 xxs:py-6 rounded-md text-base font-medium dark:hover:text-white hover:text-black-800 border-0 w-full flex justify-between text-gray-500',
              open && 'mb-2'
            )}
          >
            <span className="xxs:inline-flex font-medium text-base xxs:flex-nowrap items-center">
              <span className="text-blue-600 xxs:mr-5">{icon}</span> {name}
              {tag && (
                <div className="xxs:ml-2">
                  <Tag color="green" condensed>
                    {tag}
                  </Tag>
                </div>
              )}
            </span>
            <ChevronRightIcon
              className={`${
                open ? 'rotate-90 transform' : ''
              } h-5 w-5 text-grey-700`}
            />
          </Disclosure.Button>
          <Disclosure.Panel className="flex flex-column px-4 p-0 border-t-0 border-b-0 border-r-0 border-l-2 border-solid border-blue-400 mx-2 gap-2 mb-2">
            {subMenus?.map((subMenu, index) => (
              <div key={index} className="flex flex-column">
                <span className="text-sm mb-2 dark:text-gray-400 text-gray-600">
                  {subMenu.description}
                </span>
                {subMenu.items.map((navItem) => (
                  <NavLink
                    key={navItem.label}
                    to={navItem.href}
                    onClick={() => {
                      closeNav?.();
                    }}
                    className={(active) =>
                      classNames(
                        'p-2 w-full dark:text-gray-200 text-gray-800 dark:hover:bg-blue-900 border-0 rounded text-base',
                        active && 'bg-blue-200 dark:bg-blue-800'
                      )
                    }
                  >
                    {navItem.label}
                  </NavLink>
                ))}
              </div>
            ))}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default ExpandableItem;
