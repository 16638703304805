import { useMemo } from 'react';
import * as yup from 'yup';
import { ObjectShape, TypeFromShape } from 'yup';
import { toQueryObject, toQueryString } from './query-string';
import { useHistory, useLocation } from 'react-router-dom';

const queryObjectOptions = { arrayFormat: 'bracket' } as const;

export function useQueryParams<T extends ObjectShape>(schema: T) {
  const history = useHistory();
  const { pathname, search } = useLocation();

  const queryParams = useMemo(
    () =>
      yup
        .object(schema)
        .validateSync(
          toQueryObject(search, queryObjectOptions)
        ) as TypeFromShape<T, any>,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [search]
  );

  const setQueryParams = (qp: Partial<TypeFromShape<T, any>>) => {
    history.push(
      `${pathname}?${toQueryString(
        yup.object(schema).validateSync(qp),
        queryObjectOptions
      )}`
    );
  };

  return [queryParams, setQueryParams] as const;
}
