import { OtpMechanism, UserV1 } from 'api/user';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useState,
} from 'react';
import {
  deleteUserCookies,
  getUserFromSession,
  setUserCookie,
} from './userCookies';
import axios from 'axios';
import * as Sentry from '@sentry/react';
import { transactionId } from 'helpers/api/apiCore';

type UserTwoFactor = {
  email: string;
  twoFactor: true;
  mechanism: OtpMechanism;
};

interface UserState {
  user: UserV1 | UserTwoFactor | undefined;
  startTwoFactor: (user: Omit<UserTwoFactor, 'twoFactor'>) => void;
  loginUser: (user: UserV1) => void;
  updateUser: (user: UserV1) => void;
  logoutUser: () => void;
}
type StakeMzrContextState = UserState;
export type User = UserV1;

export const UserContext = createContext([] as unknown as StakeMzrContextState);

export const UserContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [user, setUser] = useState<UserV1 | UserTwoFactor | undefined>(() => {
    const userFromSession = getUserFromSession() as any as UserV1 | undefined;

    if (userFromSession) {
      Sentry.configureScope((scope) => {
        scope.setUser({
          id: userFromSession.id?.toString(),
        });
        scope.setTag('transaction_id', transactionId);
      });

      axios.defaults.headers.common.Authorization =
        'Bearer ' + userFromSession.access_token;
      return userFromSession;
    }
  });

  const loginUser = useCallback((data: UserV1) => {
    axios.defaults.headers.common.Authorization = 'Bearer ' + data.access_token;

    setUser(data);
    setUserCookie(data);
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: data.id.toString(),
      });
      scope.setTag('transaction_id', transactionId);
    });
  }, []);

  const updateUser = useCallback((data: UserV1) => {
    setUser(data);
    setUserCookie(data);
  }, []);

  const logoutUser = useCallback(() => {
    delete axios.defaults.headers.common.Authorization;
    deleteUserCookies();

    if (window.Telegram?.WebApp) {
      window.Telegram.WebApp.close();
    } else {
      window.location.href = '/';
    }
  }, []);

  const startTwoFactor = useCallback(
    (data: Omit<UserTwoFactor, 'twoFactor'>) => {
      setUser({ ...data, twoFactor: true });
    },
    []
  );

  return (
    <UserContext.Provider
      value={{ user, logoutUser, startTwoFactor, loginUser, updateUser }}
    >
      {children}
    </UserContext.Provider>
  );
};

export function useUserState() {
  return useContext(UserContext);
}
