import { lazyWithRetry, PrivateRoute } from 'modules/routing';
import { Redirect } from 'react-router-dom';

const DcaBotOpenPositionsPerformance = lazyWithRetry(
  () => import('./positions/open-positions-performance')
);
const DCABotSubscriptions = lazyWithRetry(
  () => import('./subscriptions/dca-bot-subscriptions')
);
const DCABotSubscriptionDetails = lazyWithRetry(
  () => import('./subscriptions/[:subscriptionId]/index')
);

const DCABotSubscriptionBulkEdit = lazyWithRetry(
  () => import('./subscriptions/subscription-bulk-edit/index')
);
const DcaBotHistorySubscriptionsPerformance = lazyWithRetry(
  () => import('./subscriptions/dca-bot-subscriptions-history')
);
const DcaBotHistoryPositionsHistory = lazyWithRetry(
  () => import('./positions/history/index')
);
const DCABotSettingsDetails = lazyWithRetry(
  () => import('./bots/[strategyId]')
);
const DCABotsNew = lazyWithRetry(() => import('./create/index'));
const DCABotEdit = lazyWithRetry(() => import('./create/edit'));
const DCABots = lazyWithRetry(() => import('./bots/index'));

const NewDCABotCreated = lazyWithRetry(
  () => import('modules/bots-performance/modals/bot-created-modal')
);

function redirectDcaWithStrategyId(arg: string, useArg: boolean) {
  if (useArg) {
    return '/dashboard/dca-bots/bots/' + arg + '/settings-details';
  } else {
    return '/dashboard/dca-bots/bots';
  }
}

export const routes = [
  {
    path: '/dashboard/dca-bots/open-positions',
    name: 'DcaBotOpenPositionsPerformance',
    component: DcaBotOpenPositionsPerformance,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots/bots/:strategyId/settings-details',
    name: 'DCABotSettingsDetails',
    component: DCABotSettingsDetails,
    route: PrivateRoute,
    subroutes: [
      {
        path: '/dashboard/dca-bots/bots/:strategyId/settings-details/new-bot-created',
        name: 'NewDCABotCreated',
        config: {
          redirect: redirectDcaWithStrategyId,
          useArg: true,
          botType: 'dca-bot',
        },
        component: NewDCABotCreated,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/dashboard/dca-bots/subscriptions-history',
    name: 'DcaBotHistorySubscriptionsPerformance',
    component: DcaBotHistorySubscriptionsPerformance,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots/positions-history',
    name: 'DcaBotHistoryPositionsHistory',
    component: DcaBotHistoryPositionsHistory,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots/active-subscriptions/:subscriptionId/details',
    name: 'DCABotSubscriptionDetails',
    component: DCABotSubscriptionDetails,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots/active-subscriptions/bulk-edit',
    name: 'DCABotSubscriptionsBulkEdit',
    component: DCABotSubscriptionBulkEdit,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots/active-subscriptions',
    name: 'DCABotSubscriptions',
    component: DCABotSubscriptions,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots/create-new',
    name: 'DCABotsCreateNew',
    component: DCABotsNew,
    exact: true,
    route: PrivateRoute,
  },

  {
    path: '/dashboard/dca-bots/:botId/:action',
    name: 'DCABotEdit',
    component: DCABotEdit,
    exact: true,
    route: PrivateRoute,
  },

  {
    path: '/dashboard/dca-bots/bots',
    name: 'DCABots',
    component: DCABots,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/dca-bots',
    component: () => <Redirect to="/dashboard/dca-bots/active-subscriptions" />,
    route: PrivateRoute,
    exact: true,
  },
];
