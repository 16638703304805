import { useEffect, useRef } from 'react';
import { matchPath, useLocation } from 'react-router-dom';

interface Props {
  modalRoutes: string[];
}

export function ScrollToTop({ modalRoutes }: Props) {
  const { pathname } = useLocation();
  const previousRoutePath = useRef<string | null>(null);

  const scrollToTop = () => {
    const isModalRoute = modalRoutes.some((modalRoutePath) => {
      const result = matchPath(pathname, {
        path: modalRoutePath,
        exact: true,
        strict: true,
      });
      return result !== null;
    });

    const isMovingBackToRoute =
      previousRoutePath.current &&
      modalRoutes.some((modalRoutePath) => {
        const result = matchPath(previousRoutePath.current!, {
          path: modalRoutePath,
          exact: true,
          strict: true,
        });
        return result !== null;
      });

    if (isMovingBackToRoute) {
      document
        .querySelector<HTMLElement>(
          `a[href$="${previousRoutePath.current || ''}"] > button`
        )
        ?.focus();
    }

    if (isModalRoute || isMovingBackToRoute) return;

    window.scrollTo({
      top: 0,
      left: 0,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      behavior: 'instant',
    });
  };

  useEffect(() => {
    scrollToTop();
    previousRoutePath.current = pathname;
  }, [pathname]);

  return null;
}
