import { lazyWithRetry, PrivateRoute } from 'modules/routing';

const RewardsDashboard = lazyWithRetry(() => import('./index'));

export const rewardsDashboardRoutes = [
  {
    path: '/dashboard/rewards-dashboard',
    name: 'RewardsDashboard',
    component: RewardsDashboard,
    route: PrivateRoute,
  },
];
