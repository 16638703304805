import React from 'react';
import { FullPageNotification } from 'modules/bots-performance/components/full-page-notification';
import mizarIcon from '../assets/img/logo/mizar_icon.svg';

type Props = React.ComponentProps<'div'>;

const Maintenance = React.forwardRef<HTMLDivElement, Props>(() => {
  return (
    <div className="flex justify-center bg-black-900 h-screen 	">
      <div className="max-w-2xl flex justify-center">
        <FullPageNotification
          icon={<img alt="mizar-icon" src={mizarIcon} height="100" />}
          title="Under Maintenance"
          link="https://discord.com/invite/gM4mAYMeWG"
          hrefTitle="GET UPDATES"
          subTitle="Mizar is currently undergoing scheduled maintenance. Our dedicated devs are diligently working to bring you the best trading tools. We appreciate your patience and understanding. We'll be back soon!"
          footer={true}
        />
      </div>
    </div>
  );
});
export default Maintenance;
