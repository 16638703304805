import { lazyWithRetry, PrivateRoute } from 'modules/routing';

const SmartTradePositions = lazyWithRetry(() => import('./open-positions'));
const CancelPositionModal = lazyWithRetry(
  () => import('./components/CancelPositionModal')
);
const ClosePositionModal = lazyWithRetry(
  () => import('./components/ClosePositionModal')
);

const SmartTradePositionsHistory = lazyWithRetry(
  () => import('./positions-history')
);

export const routes = [
  {
    path: '/dashboard/smart-trading/positions',
    name: 'SmartTradingPositions',
    component: SmartTradePositions,
    route: PrivateRoute,
    subroutes: [
      {
        path: '/dashboard/smart-trading/positions/:positionId/cancel',
        name: 'CancelPositionModal',
        config: { redirect: '/dashboard/smart-trading/positions' },
        component: CancelPositionModal,
        route: PrivateRoute,
      },
      {
        path: '/dashboard/smart-trading/positions/:positionId/close',
        name: 'ClosePositionModal',
        config: { redirect: '/dashboard/smart-trading/positions' },
        component: ClosePositionModal,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/dashboard/smart-trading/history',
    name: 'SmartTradingPositions',
    component: SmartTradePositionsHistory,
    route: PrivateRoute,
  },
];
