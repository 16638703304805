import { lazyWithRetry } from 'modules/routing';
import { Route } from 'react-router-dom';

const ApplyForJob = lazyWithRetry(() => import('./application'));

export const careersRoutes = [
  {
    path: '/careers/application',
    name: 'ApplyForJob',
    component: ApplyForJob,
    route: Route,
    exact: true,
  },
];
