import { PropsWithChildren, ReactElement } from 'react';
import { webAppContext, DEFAULT_WEBAPP, SystemContextProvider } from './core';

export type WebAppProviderProps = PropsWithChildren<{}>;

export const WebAppProvider = ({
  children,
}: WebAppProviderProps): ReactElement => {
  return (
    <SystemContextProvider>
      <webAppContext.Provider value={DEFAULT_WEBAPP}>
        {children}
      </webAppContext.Provider>
    </SystemContextProvider>
  );
};
