export interface ApiError extends Error {
  errors: FieldError[];
}

type FieldError = {
  loc: string[];
  msg: string;
};

function formatStructuredError(error: ApiError) {
  return error.errors
    .map((e) => (typeof e === 'string' ? e : e.msg))
    .join(', ');
}

export function formatApiError(error: unknown, fallbackMsg?: string) {
  if (error && typeof error === 'object' && 'errors' in error) {
    return formatStructuredError(error as ApiError);
  }
  if (typeof error === 'string') {
    return error;
  }

  if (
    error &&
    typeof error === 'object' &&
    'message' in error &&
    typeof error.message === 'string'
  ) {
    return error.message;
  }

  if (fallbackMsg) {
    return fallbackMsg;
  }

  return 'Something went wrong.';
}

const errorLocationAssigner = (error: FieldError) => ({
  ...error,
  loc: error.loc ? error.loc : ['body', '__root__'],
});

export const standarizeError = (error: ApiError) => {
  try {
    const isTransportLayerError =
      typeof error === 'string' ||
      error instanceof String ||
      error === undefined;
    const errorsList = isTransportLayerError
      ? [{ loc: ['body', '__root__'], msg: error }]
      : error.errors.map(errorLocationAssigner);
    return errorsList;
  } catch (e: any) {
    return [{ loc: ['body', '__root__'], msg: e.message }];
  }
};
