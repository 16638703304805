import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { createContext, PropsWithChildren, useState } from 'react';

export const SidebarContext = createContext<{
  expanded: boolean;
  setExpanded: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  expanded: false,
  setExpanded: () => {},
});

export default function SidebarContextProvider({
  children,
}: PropsWithChildren<{}>) {
  const isDesktop = useMediaQuery(screenGte.medium);
  const [expanded, setExpanded] = useState(isDesktop);
  return (
    <SidebarContext.Provider value={{ expanded, setExpanded }}>
      {children}
    </SidebarContext.Provider>
  );
}
