import { twMerge } from 'tailwind-merge';
import bgDesktop from 'assets/img/landing_page_new/backgrounds/signup_login-bk_tr.png';
import config from 'config';
import mizarLogo from 'assets/img/logo/main_logo.svg';

export function AuthLayout({ children, ...rest }: React.ComponentProps<'div'>) {
  return (
    <div
      {...rest}
      className={twMerge(
        'flex justify-center bg-black-800 text-white',
        rest.className
      )}
      style={{
        backgroundImage: `url(${bgDesktop})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        ...rest.style,
      }}
    >
      <div className="min-h-screen max-w-7xl ">
        <a
          href={'https://' + config.APPLICATION_DOMAIN}
          rel="noopener noreferrer"
        >
          <div className="flex justify-center content-center items-center py-6 lg:py-14">
            <img alt="mizar logo light" src={mizarLogo} height="50" />
          </div>
        </a>
        <div className="flex justify-center content-center items-center lg:pt-4">
          {children}
        </div>
      </div>
    </div>
  );
}
