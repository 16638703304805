import { routes as dcaBotsRoutes } from './dca-bots/routes';
import { marketplaceRoutes } from './marketplace/routes';
import { exchangesRoutes } from './exchanges/routes';
import { feeManagerRoutesNew } from './fee-manager-new/routes';
import { consoleRoutes } from './console/routes';
import { stakingRoutes } from './staking/routes';
import { lazyWithRetry, PrivateRoute } from 'modules/routing';
import { settingsRoutes } from './settings/routes';
import { rewardsDashboardRoutes } from './rewards-dashboard/routes';
import { CexRoute } from 'modules/DexSwitch/DexRoute';

const DashboardError404 = lazyWithRetry(() => import('./Error404'));

const cexRoutes = [
  ...marketplaceRoutes,
  ...consoleRoutes,
  ...exchangesRoutes,
  ...dcaBotsRoutes,
].map((route) => ({
  ...route,
  route: CexRoute(route.route),
}));

export const dashboardRoutes = [
  ...cexRoutes,
  ...feeManagerRoutesNew,
  ...stakingRoutes,
  ...settingsRoutes,
  ...rewardsDashboardRoutes,
  {
    path: '/dashboard/',
    name: 'Error404',
    component: DashboardError404,
    route: PrivateRoute,
  },
];
