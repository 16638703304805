import { without } from 'lodash';
import { createContext, FC, useContext, useState } from 'react';

export interface SnackBarMessage {
  type: 'success' | 'error';
  title: string;
  message: string;
  id: string | number;
}

export const SnackBarContext = createContext<{
  messages: SnackBarMessage[];
  setMessages: React.Dispatch<React.SetStateAction<SnackBarMessage[]>>;
  addNewMessage: (message: Omit<SnackBarMessage, 'id'>) => void;
}>({
  setMessages: () => {},
  messages: [],
  addNewMessage: () => {},
});

export const useSnackBar = () => {
  return useContext(SnackBarContext);
};

const SnackBarContextProvider: FC = ({ children }) => {
  const [messages, setMessages] = useState<SnackBarMessage[]>([]);
  const addNewMessage = (message: Omit<SnackBarMessage, 'id'>) => {
    const messageWithId = { ...message, id: Date.now() };
    setMessages((mess) => [...mess, messageWithId]);
    setTimeout(() => {
      setMessages((mess) => without(mess, messageWithId));
    }, 3000);
  };
  return (
    <SnackBarContext.Provider value={{ messages, setMessages, addNewMessage }}>
      {children}
    </SnackBarContext.Provider>
  );
};

export default SnackBarContextProvider;
