import React from 'react';
import { twMerge } from 'tailwind-merge';

type Props = React.ComponentProps<'div'>;

export const Loader = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={twMerge('spinner-border text-mizar', props.className)}
      role="status"
    />
  );
});
