import { formatApiError } from 'helpers/api/apiErrors';
import { Notification } from './notification';

type Props = Omit<
  React.ComponentProps<typeof Notification>,
  'children' | 'type'
> & {
  error: unknown;
  fallback?: string;
};

export function ApiErrorNotification({ error, fallback, ...props }: Props) {
  if (!error) {
    return <></>;
  }
  return (
    <Notification {...props} type="error">
      {formatApiError(error, fallback)}
    </Notification>
  );
}
