import { PrivateRoute, lazyWithRetry } from 'modules/routing';

const Onboard = lazyWithRetry(() => import('./index'));

export const onboardRoutes = [
  {
    path: '/onboard',
    name: 'Onboard',
    component: Onboard,
    route: PrivateRoute,
  },
];
