import { Player } from '@lottiefiles/react-lottie-player';
import { Link } from 'react-router-dom';

import InvestSuccessful from 'modules/bot-investment/Steps/ConfirmInvest/investmentSuccessful.json';
import Button from 'modules/shared-components/button/button';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import { useQueryParams } from 'modules/routing/useQueryParams';
import * as yup from 'yup';
import { Chains } from 'api/d-wallets';

const TradeSuccesful = () => {
  const isLarge = useMediaQuery(screenGte.large);
  const [{ chain, address }] = useQueryParams({
    chain: yup.string().oneOf([Chains.Ethereum, Chains.Base]),
    address: yup.string(),
  });

  return (
    <div className="">
      <div className="flex justify-center items-center">
        <Player
          autoplay
          loop
          src={InvestSuccessful}
          className="xxs:w-[240px] 2xl:w-1/3"
        />
      </div>
      <div className="xxs:mt-8 text-3xl lg:text-4xl font-medium text-center text-green-500">
        Snipe Successfully Created
      </div>
      <div className="xxs:py-3">
        <div className="xxs:my-3 text-center">
          <Button
            as={Link}
            to={isLarge ? '/dex/snipe' : '/dex/trade-monitoring'}
            className="xs:mr-2"
            variant="primary"
          >
            VIEW YOUR SNIPES
          </Button>
        </div>
        <div className="text-center dark:text-white">
          <Button as={Link} to={`/dex/snipe/new/${chain}/${address}`}>
            CREATE A NEW SNIPE
          </Button>
        </div>
      </div>
    </div>
  );
};
export default TradeSuccesful;
