import { lazyWithRetry, PrivateRoute } from 'modules/routing';

const APISettings = lazyWithRetry(() => import('./api/APISettings'));

const Notifications = lazyWithRetry(() => import('./notifications'));
const CexSettings = lazyWithRetry(() => import('./cex'));
const AccountSettings = lazyWithRetry(() => import('./account'));

export const settingsRoutes = [
  {
    path: '/dashboard/settings/API',
    name: 'APISettings',
    component: APISettings,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/settings/account',
    name: 'NewAccountSettings',
    component: AccountSettings,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/settings/cex',
    name: 'Notifications',
    component: CexSettings,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/settings/notifications',
    name: 'Notifications',
    component: Notifications,
    route: PrivateRoute,
  },
];
