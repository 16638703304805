import { forwardRef, InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import Tooltip from 'modules/shared-components/tooltip';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  infoRight?: React.ReactNode;
  infoText?: string;
  errorText?: string;
  suffix?: string;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      className,
      infoText,
      infoRight,
      errorText,
      suffix,
      name,
      ...props
    },
    ref
  ) => (
    <div className="flex flex-1 flex-column relative">
      <div className="flex justify-between dark:text-white">
        {label && (
          <label
            htmlFor={name}
            className="xxs:pb-1.5 inline-flex gap-1 items-center w-fit"
          >
            {label}
            {infoText && <Tooltip.Info text={infoText} />}
          </label>
        )}
        {infoRight && <div className="xxs:pb-1.5">{infoRight}</div>}
      </div>

      <input
        ref={ref}
        name={name}
        id={name}
        autoComplete="off"
        {...props}
        className={twMerge(
          'xxs:text-xs xxs:shadow xxs:appearance-none xxs:border xxs:rounded w-full py-2 xxs:px-3 text-black-700 leading-tight focus:outline-none focus:shadow-outline xxs:bg-white dark:bg-black-700 dark:border-black-600 border-black-300 border-solid dark:text-white dark:focus:border-blue-500 border-1',
          suffix && 'pr-12',
          className
        )}
      />
      {suffix && (
        <div
          className={twMerge(
            'pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2',
            errorText ? 'xxs:top-0' : 'top-6'
          )}
        >
          <span
            className="dark:text-black-300 text-black-500 xxs:text-sm"
            id="price-currency"
          >
            {suffix}
          </span>
        </div>
      )}
      {errorText && (
        <div className="xxs:mt-1.5 xxs:text-red-400 xxs:text-xs">
          {errorText}
        </div>
      )}
    </div>
  )
);

export default Input;
