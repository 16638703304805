import classNames from 'classnames';
import React from 'react';

export const TooltipContent = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      className={classNames(
        'xxs:bg-white p-2 rounded drop-shadow dark:bg-black-700 xxs:max-w-lg',
        props.className
      )}
    />
  );
});
