import { lazyWithRetry, PrivateRoute } from 'modules/routing';
const FeeManagerNew = lazyWithRetry(() => import('./index'));

export const feeManagerRoutesNew = [
  {
    path: '/dashboard/fee-manager',
    name: 'FeeManagerNew',
    component: FeeManagerNew,
    route: PrivateRoute,
  },
];
