import { useQuery } from '@tanstack/react-query';
import { StarLevelResponse } from 'api/star-level';
import { api } from 'helpers/api/apiCore';
import { useUserState } from 'modules/user/UserContext';

const MODULE_KEY = 'star-level';

export const starLevelKeys = {
  getStarLevel: () => [MODULE_KEY, 'star-level'],
};

export const StarLevelService = {
  getStarLevel: async () => {
    const response = await api.get<StarLevelResponse>('/api/star-level');
    return response.data;
  },
};

export const useStarLevel = () => {
  const { user } = useUserState();

  return useQuery({
    queryKey: starLevelKeys.getStarLevel(),
    queryFn: StarLevelService.getStarLevel,
    staleTime: 10 * 60 * 1000,
    enabled: !!user,
  });
};

export default StarLevelService;
