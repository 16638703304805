import React from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const SpinnerLoading = ({ color }) => {
  return (
    <div className="flex justify-center items-center">
      <div
        className={classNames(
          'spinner-border animate-spin inline-block w-5 h-5 border-2 rounded-full',
          color
        )}
        role="status"
      >
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
