import { InformationCircleIcon } from '@heroicons/react/24/outline';
import type { Placement } from '@popperjs/core';
import classNames from 'classnames';
import React, { ReactHTMLElement, useEffect, FC, useState } from 'react';
import { usePopper } from 'react-popper';
import { copyContent } from 'utils/copyContent';
import { TooltipContent } from './tooltip-content';
import { twMerge } from 'tailwind-merge';

interface TooltipProps {
  text: string | undefined;
  copyable?: boolean;
  placement?: Placement;
  className?: string;
}

type ITooltip = FC<TooltipProps> & {
  Info: FC<TooltipProps>;
};

export const Tooltip: ITooltip = ({
  children,
  text,
  copyable,
  placement = 'top',
  className,
}) => {
  const [referenceElement, setReferenceElement] =
    useState<HTMLButtonElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const [display, setDisplay] = useState(false);

  const { update, styles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement,
      strategy: 'fixed',
      modifiers: [
        { name: 'arrow', options: { element: arrowElement } },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    }
  );

  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    void update?.();
  }, [isCopied]);

  return React.isValidElement(children) ? (
    <>
      {React.cloneElement(children as ReactHTMLElement<HTMLSpanElement>, {
        ref: setReferenceElement,
        onMouseEnter: () => {
          setDisplay(true);
          void update?.();
        },
        onMouseLeave: () => {
          setDisplay(false);
          void update?.();
        },
        onClick: (e) => {
          if (copyable) {
            void copyContent(setIsCopied, text);
          }
          children.props.onClick?.(e);
        },
        className: classNames(
          children.props.className,
          copyable && 'cursor-pointer',
          className
        ),
      })}

      {display && text && (
        <TooltipContent
          className="xxs:bg-white text-white xxs:p-2 rounded drop-shadow dark:bg-black-700 xxs:max-w-sm whitespace-normal xxs:text-xs xxs:text-left xxs:leading-4"
          ref={setPopperElement}
          style={{ ...styles.popper, zIndex: 1031 }}
          {...attributes.popper}
        >
          {isCopied ? 'Copied!' : text}
          <div ref={setArrowElement} style={styles.arrow} />
        </TooltipContent>
      )}
    </>
  ) : null;
};

const Info: FC<TooltipProps> = (props) => (
  <Tooltip {...props}>
    <InformationCircleIcon
      className={twMerge(
        'text-black-400 dark:text-black-50 h-4 w-4',
        props.className
      )}
    />
  </Tooltip>
);

Tooltip.Info = Info;

export default Tooltip;
