import { Link } from 'react-router-dom';
import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';

const Footer = () => {
  const { mode } = useExchangeMode();
  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="h-100 xxs:flex xxs:flex-col md:flex-row">
          <div className="w-full" />
          <div className="w-full">
            <div className="text-md-end footer-links d-none d-md-block">
              <a
                href={
                  mode === DexSwitchMode.Cex
                    ? 'https://docs.mizar.com/c-mizar-manual/'
                    : 'https://docs.mizar.com/sniper-bot-manual'
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="grey">Docs</span>
              </a>
              <a
                href="https://discord.gg/gM4mAYMeWG"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="grey">Support</span>
              </a>
              <Link to="/contact-us">
                <span className="grey">Contact Us</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
