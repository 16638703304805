import React, { useContext } from 'react';
import createPersistedState from 'use-persisted-state';

type Theme = 'light' | 'dark';

const useThemePersistedState = createPersistedState<Theme>('theme-v2');

const themeContext = React.createContext(
  [] as unknown as [Theme, React.Dispatch<React.SetStateAction<Theme>>]
);

export function ThemeProvider({ children }: any) {
  const theme = useThemePersistedState('dark');

  return (
    <themeContext.Provider value={theme}>{children}</themeContext.Provider>
  );
}

export function useThemeState() {
  return useContext(themeContext);
}

export function useTheme() {
  return useThemeState()[0];
}
