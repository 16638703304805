/* eslint-disable react/prop-types */
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

const variants = {
  bg: 'xxs:rounded bg-black-700 hover:bg-black-600 xxs:p-1',
  default: '',
};

export const IconButton = forwardRef(function InnerButton<
  T extends 'button' | 'a' | typeof Link
>(
  {
    as,
    className,
    variant = 'default',
    ...props
  }: React.ComponentProps<T> & { as?: T; variant?: keyof typeof variants },
  ref: any
) {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const Component = as || ('button' as any);

  return (
    <Component
      ref={ref}
      {...(Component === 'button' && { type: 'button' })}
      {...props}
      className={twMerge(
        'xxs:border-none  xxs:flex xxs:items-center xxs:justify-center',
        variants[variant],
        className
      )}
    />
  );
});
