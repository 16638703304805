/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { Redirect } from 'react-router-dom';
import { lazyWithRetry, PrivateRoute } from 'modules/routing';
import { routes as smartTradePositionsRoutes } from '../modules/smart-trade-open-positions/routes';
import { careersRoutes } from '../app/careers/routes';
import { contactUsRoutes } from '../app/contact-us/routes';
import { dashboardRoutes } from '../app/dashboard/routes';
import { loginRoutes } from '../app/login/routes';
import { signUpRoutes } from '../app/sign-up/routes';
import { requestPasswordResetRoutes } from '../app/request-password-reset/routes';
import { resetPasswordRoutes } from '../app/password-reset/routes';
import { thankYouForSignUpRoutes } from '../app/thank-you-for-sign-up/routes';
import { grantAccessToStrategiesRoutes } from '../app/grant-access/routes';
import { dexRoutes } from '../app/dex/routes';
import { onboardRoutes } from '../app/onboard/routes';

import {
  DexSwitchMode,
  useExchangeMode,
} from 'modules/DexSwitch/DexSwitchContext';
import { useUser } from 'modules/user/user';
import { screenGte, useMediaQuery } from 'modules/media/use-media-query';
import config from '../config';

const BotRequestAccess = lazyWithRetry(
  () => import('../modules/request-access-investment')
);
const BotInvestment = lazyWithRetry(() => import('modules/bot-investment'));

// // root routes
const rootCexRoute = {
  path: '/dashboard',
  exact: true,
  component: () => <Redirect to="/dashboard/console" />,
  route: PrivateRoute,
};
const rootDexRoute = {
  path: '/dex',
  exact: true,
  component: function Component() {
    const isDesktop = useMediaQuery(screenGte.large);

    return (
      <Redirect
        to={
          isDesktop
            ? '/dex/snipe'
            : config.isTelegramBrowser
            ? '/dex/navigation'
            : 'dex/wallets'
        }
      />
    );
  },
  route: PrivateRoute,
};

const dexAuthProtectedRoutes = {
  path: '/dex',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: dexRoutes,
};

// dashboards
const cexPrimaryRoutes = {
  path: '/dashboard',
  name: 'Dashboards',
  icon: 'uil-home-alt',
  header: 'Navigation',
  children: [
    ...smartTradePositionsRoutes,
    {
      path: '/dashboard/:strategyType/bots/:strategyId/activate',
      name: 'BotsListActivation',
      component: BotInvestment,
      route: PrivateRoute,
    },
    {
      path: '/dashboard/:strategyType/bots/:strategyId/request-access',
      name: 'BotRequestAccess',
      component: BotRequestAccess,
      route: PrivateRoute,
    },
    {
      path: '/dashboard/bot-labs/api-bots/create-new',
      name: 'APIBotCreateNew',
      component: () => <Redirect to="/dashboard/api-bots/create-new" />,
      route: PrivateRoute,
    },
    {
      path: '/dashboard/bot-labs/dca-bots/create-new',
      name: 'DCABotCreateNew',
      component: () => <Redirect to="/dashboard/dca-bots/create-new" />,
      route: PrivateRoute,
      exact: true,
    },
    {
      path: '/dashboard/bot-labs/dca-bots/edit',
      name: 'DCABotCreateNew',
      component: () => <Redirect to="/dashboard/dca-bots/create-new" />,
      route: PrivateRoute,
    },

    ...dashboardRoutes,
  ],
};

// @deprecated
// eslint-disable-next-line @typescript-eslint/naming-convention
const __publicRoutesThatShouldBeMovedToNextJs = [
  ...contactUsRoutes,
  ...careersRoutes,
];

// flatten the list of all nested routes
const flattenRoutes = (routes: any[]) => {
  let flatRoutes: any[] = [];

  routes = routes || [];
  routes.forEach((item) => {
    flatRoutes.push(item);

    if (Array.isArray(item.children) && Array.isArray(flatRoutes)) {
      flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
    }
  });
  return flatRoutes;
};

// auth
const authRoutes = [
  ...loginRoutes,
  ...grantAccessToStrategiesRoutes,
  ...thankYouForSignUpRoutes,
  ...signUpRoutes,
  ...requestPasswordResetRoutes,
  ...resetPasswordRoutes,
];

const rootRedirect = {
  path: '/',
  component: function Component() {
    const { mode } = useExchangeMode();
    const user = useUser();

    const destination = !user.primary_product_interest
      ? '/onboard'
      : mode === DexSwitchMode.Dex
      ? '/dex'
      : mode === DexSwitchMode.Cex
      ? '/dashboard/console'
      : user.primary_product_interest === 'cex'
      ? '/dashboard/console'
      : user.primary_product_interest === 'dex'
      ? '/dex'
      : '/onboard';

    return <Redirect to={destination} />;
  },
  exact: true,
  route: PrivateRoute,
};

// All routes
const publicRoutes = [
  ...authRoutes,
  ...__publicRoutesThatShouldBeMovedToNextJs,
];

const dexAuthProtectedFlattenRoutes = flattenRoutes([
  rootDexRoute,
  dexAuthProtectedRoutes,
]);
const cexAuthProtectedFlattenRoutes = flattenRoutes([
  rootCexRoute,
  cexPrimaryRoutes,
]);

const authProtectedFlattenRoutes = [
  ...cexAuthProtectedFlattenRoutes,
  ...dexAuthProtectedFlattenRoutes,
];
const noLayoutFlattenRoutes = flattenRoutes([
  ...publicRoutes,
  ...onboardRoutes,
  rootRedirect,
]);

export { rootRedirect, noLayoutFlattenRoutes, authProtectedFlattenRoutes };
