import { lazyWithRetry, PrivateRoute } from 'modules/routing';

const Staking = lazyWithRetry(() => import('./index'));

export const stakingRoutes = [
  {
    path: '/dashboard/staking',
    name: 'SmartTrading',
    component: Staking,
    route: PrivateRoute,
  },
];
