import config from '../config';

export function getAssetsUrl() {
  if (config.IS_E2E || process.env.REACT_APP_CLOUDFLARE_E2E_SERVICE_TOKEN) {
    return 'https://cdnassets.mizar-test.com';
  }

  if (window.location.host.includes('localhost')) {
    return 'http://localhost:5000';
  }

  return 'https://cdnassets.' + config.APPLICATION_DOMAIN;
}
