import { useEffect } from 'react';
import { DexSwitchMode, useExchangeMode } from './DexSwitchContext';

export function DexRoute<T extends {}>(
  Component: ((props: T) => JSX.Element) | React.ComponentClass<T>
) {
  return function Inner(props: T) {
    const { mode, setMode } = useExchangeMode();
    useEffect(() => {
      if (mode !== DexSwitchMode.Dex) {
        setMode(DexSwitchMode.Dex);
      }
    }, [mode]);

    return <Component {...props} />;
  };
}

export function CexRoute<T extends {}>(
  Component: ((props: T) => JSX.Element) | React.ComponentClass<T>
) {
  return function Inner(props: T) {
    const { mode, setMode } = useExchangeMode();
    useEffect(() => {
      if (mode !== DexSwitchMode.Cex) {
        setMode(DexSwitchMode.Cex);
      }
    }, [mode]);

    return <Component {...props} />;
  };
}
