import Cookies from 'js-cookie';
import config from 'config';
import CryptoJS from 'crypto-js';

export const AUTH_SESSION_KEY = 'mizar_user';
export const cookies = Cookies.withConverter({
  read(value, name) {
    if (name === AUTH_SESSION_KEY) {
      if (config.SENTRY_ENVIRONMENT === 'mainnet') {
        const cookie = Cookies.converter.read(value, name);
        const bytes = CryptoJS.AES.decrypt(cookie, config.ENCRYPTION_SEED);
        const originalText = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(originalText);
      } else {
        const cookie = Cookies.converter.read(value, name);
        return JSON.parse(cookie);
      }
    }
    return Cookies.converter.read(value, name);
  },
  write(user) {
    if (config.SENTRY_ENVIRONMENT === 'mainnet')
      return CryptoJS.AES.encrypt(
        JSON.stringify(user),
        config.ENCRYPTION_SEED
      ).toString();

    return JSON.stringify(user);
  },
});
