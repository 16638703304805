import { hasActiveJWT } from 'helpers/api/apiCore';
import { useRedirectContext } from 'modules/routing/RedirectContext';
import { useUserState } from 'modules/user/UserContext';
import { Redirect, Route, useHistory } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import UserService from 'api/services/UserService';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { Loader } from 'utils/Loader';
import config from 'config';
import { ApiErrorNotification } from 'modules/shared-components/notification/ApiErrorNotification';

export const NotLoggedInRoute = ({
  component: Component,
  children,
  ...rest
}: {
  component?: any;
  children?: any;
}) => {
  const history = useHistory();
  const { user } = useUserState();
  const { redirectUrl, setRedirectUrl } = useRedirectContext();

  const isLoggedIn = user && 'access_token' in user && hasActiveJWT(user);

  // Maybe should use also isLoggedIn to get token from session? For some reason user is sometimes logged out in telegram browser
  if (config.isTelegramBrowser) {
    return <TelegramLogin />;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLoggedIn) {
          const redirectTo = redirectUrl;
          setRedirectUrl('');
          return (
            <Redirect
              to={(history?.location?.state as any)?.url || redirectTo || '/'}
            />
          );
        }
        if (children) {
          return children;
        }

        return <Component {...props} />;
      }}
    />
  );
};

function TelegramLogin() {
  const { loginUser, user } = useUserState();
  const history = useHistory();
  const { redirectUrl, setRedirectUrl } = useRedirectContext();
  const isLoggedIn = user && 'access_token' in user && hasActiveJWT(user);

  const { mutate, isLoading, error } = useMutation({
    mutationFn: UserService.loginWithTelegram,
    onSuccess: ({ user_data }) => {
      loginUser(user_data);
    },
  });

  useEffect(() => {
    if (!isLoggedIn) {
      mutate({
        initData: window.Telegram!.WebApp!.initData,
        referralCode: Cookies.get('referrerId') || null,
        source: 'bot',
        telegramEnvironment: config.isLocalTgDev ? 'test' : 'prod',
      });
    }
  }, [isLoggedIn]);

  if (isLoading) {
    return <Loader className="w-10 h-10 m-auto" />;
  }

  if (isLoggedIn) {
    const redirectTo = redirectUrl;
    setRedirectUrl('');
    return (
      <Redirect
        to={(history?.location?.state as any)?.url || redirectTo || '/dex'}
      />
    );
  }
  if (error) {
    return <ApiErrorNotification error={error} />;
  }

  return <></>;
}
