import {
  InformationCircleIcon,
  XCircleIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  WrenchIcon,
} from '@heroicons/react/24/outline';
import { twMerge } from 'tailwind-merge';

export type NotificationProps = {
  children: React.ReactNode;
  title?: string;
  onClose?: () => void;
  type: 'error' | 'success' | 'warning' | 'info' | 'flat' | 'update';
  size?: 'default' | 'small' | 'xSmall' | 'xxSmall';
} & React.ComponentProps<'div'>;

const errorColor = 'xxs:bg-red-100 xxs:text-red-600 xxs:border-red-600';
const successColor = 'xxs:bg-green-50 xxs:text-green-600 xxs:border-green-600';
const infoColor = 'xxs:bg-blue-50 xxs:text-blue-600 xxs:border-blue-600';
const warningColor =
  'xxs:bg-yellow-50 xxs:border-yellow-600 xxs:text-yellow-600';
const flatColor = 'xxs:border xxs:border-black-600 xxs:text-white-600';

export function Notification({
  children,
  onClose,
  type,
  size = 'default',
  ...props
}: NotificationProps) {
  return (
    <div
      {...props}
      className={twMerge(
        'xxs:border xxs:border-solid xxs:rounded relative flex xxs:text-sm items-center xxs:mx-1 lg:mx-0',
        size === 'default'
          ? 'xxs:px-2 xxs:py-1 xxs:gap-2'
          : size === 'small'
          ? 'xxs:px-2 xxs:py-2 xxs:gap-1'
          : size === 'xSmall'
          ? 'xxs:p-1 xxs:gap-1 xxs:text-xs'
          : size === 'xxSmall'
          ? 'xxs:px-2 xxs:py-0.5 xxs:gap-1'
          : '',
        type === 'error' || type === 'update'
          ? errorColor
          : type === 'success'
          ? successColor
          : type === 'info'
          ? infoColor
          : type === 'flat'
          ? flatColor
          : warningColor,
        props.className
      )}
      role="alert"
    >
      {type === 'error' ? (
        <XCircleIcon
          className={twMerge(
            size === 'xxSmall'
              ? 'h-[13px] w-[13px]'
              : size === 'small' || size === 'xSmall'
              ? 'h-4 w-4'
              : 'h-6 w-6',
            'text-red-600 shrink-0'
          )}
          aria-hidden="true"
        />
      ) : type === 'update' ? (
        <WrenchIcon
          className={twMerge(
            size === 'xxSmall'
              ? 'h-[13px] w-[13px]'
              : size === 'small' || size === 'xSmall'
              ? 'h-4 w-4'
              : 'h-6 w-6',
            'text-red-600 shrink-0'
          )}
          aria-hidden="true"
        />
      ) : type === 'warning' ? (
        <ExclamationCircleIcon
          className={twMerge(
            size === 'xxSmall'
              ? 'h-[13px] w-[13px]'
              : size === 'small' || size === 'xSmall'
              ? 'h-4 w-4'
              : 'h-6 w-6',
            'text-yellow-600 shrink-0'
          )}
          aria-hidden="true"
        />
      ) : type === 'success' ? (
        <CheckCircleIcon
          className={twMerge(
            size === 'xxSmall'
              ? 'h-[13px] w-[13px]'
              : size === 'small' || size === 'xSmall'
              ? 'h-4 w-4'
              : 'h-6 w-6',
            'text-green-600 shrink-0'
          )}
          aria-hidden="true"
        />
      ) : (
        <InformationCircleIcon
          className={twMerge(
            size === 'xxSmall'
              ? 'h-[13px] w-[13px]'
              : size === 'small' || size === 'xSmall'
              ? 'h-4 w-4'
              : 'h-6 w-6',
            'xxs:text-blue-600 shrink-0',
            type === 'flat' && 'xxs:text-white'
          )}
          aria-hidden="true"
        />
      )}

      <span className="xxs:font-normal xxs:overflow-hidden w-full">
        {children}
      </span>
      {onClose && (
        <span className="ml-auto flex items-center">
          <svg
            className={twMerge(
              'fill-current',
              size === 'xxSmall' ? 'h-4 w-4' : 'h-6 w-6',
              type === 'error' || type === 'update'
                ? 'text-red-600'
                : type === 'success'
                ? 'text-green-600'
                : type === 'warning'
                ? 'text-yellow-600'
                : type === 'flat'
                ? 'xxs:text-white'
                : 'text-blue-500'
            )}
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            onClick={onClose}
          >
            <title>Dismiss Notification</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      )}
    </div>
  );
}

export function NotificationLarge({
  title,
  children,
  onClose,
  type,
  ...props
}: NotificationProps) {
  return (
    <div
      {...props}
      className={twMerge(
        'border-t-4 lg:border-t-8 xxs:border-solid xxs:border-b-0 xxs:border-x-0 xxs:px-2 lg:px-4 xxs:py-2 xxs:rounded relative xxs:flex xxs:gap-4 text-sm items-center',
        type === 'error' || type === 'update'
          ? errorColor
          : type === 'success'
          ? successColor
          : type === 'info'
          ? infoColor
          : warningColor,
        props.className
      )}
      role="alert"
    >
      <div className="flex space-x-2 lg:space-x-4">
        <div>
          {type === 'error' ? (
            <XCircleIcon
              className="h-6 w-6 lg:h-8 lg:w-8 text-red-600 shrink-0"
              aria-hidden="true"
            />
          ) : type === 'warning' ? (
            <ExclamationCircleIcon
              className="h-6 w-6 lg:h-8 lg:w-8 text-yellow-600 shrink-0"
              aria-hidden="true"
            />
          ) : type === 'success' ? (
            <CheckCircleIcon
              className="h-6 w-6 lg:h-8 lg:w-8  text-green-600 shrink-0"
              aria-hidden="true"
            />
          ) : (
            <InformationCircleIcon
              className="h-6 w-6 lg:h-8 lg:w-8  text-blue-600 shrink-0"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="flex flex-col space-y-1">
          {title && <p className="xxs:text-base xxs:font-bold mb-0">{title}</p>}
          <span className="xxs:font-regular xxs:text-sm xxs:overflow-hidden">
            {children}
          </span>
        </div>
      </div>

      {onClose && (
        <span className="absolute top-0 bottom-0 right-0 xxs:px-4 xxs:py-1 flex items-center">
          <svg
            className={
              'fill-current h-6 w-6 ' +
              (type === 'error' || type === 'update'
                ? 'text-red-600'
                : type === 'success'
                ? 'text-green-600'
                : type === 'warning'
                ? 'text-yellow-600'
                : 'text-blue-500')
            }
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            onClick={onClose}
          >
            <title>Dismiss Notification Large</title>
            <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
          </svg>
        </span>
      )}
    </div>
  );
}
