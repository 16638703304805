import {
  createContext,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import type { WebApp } from './twa-types';

declare global {
  interface Window {
    Telegram?: {
      WebApp: WebApp | null;
      Login: {
        auth: (options: AuthOptions, callback: Callback) => void;
      };
    };
  }
}

interface AuthOptions {
  bot_id: number;
  request_access?: boolean;
  lang?: string;
}

export interface TgAuthData {
  auth_date: number;
  first_name: string;
  hash: string;
  id: number;
  last_name: string;
  username: string;
  // I think there could be other properties too
}

type Callback = (dataOrFalse: TgAuthData | false) => void;

export const DEFAULT_WEBAPP =
  typeof window !== 'undefined' && window?.Telegram?.WebApp
    ? window.Telegram.WebApp
    : null;

export const webAppContext = createContext<WebApp | null>(DEFAULT_WEBAPP);

type SystemContext = {
  MainButton: ButtonsProps | null;
  BackButton: BackButtonProps | null;
};

type BackButtonProps = {
  id: number;
  onClick?: () => void;
};

type ButtonsProps = {
  id: number;
  text?: string;
  color?: string;
  textColor?: string;
  isActive?: boolean;
  isVisible?: boolean;
  isProgress?: boolean;
  onClick?: () => void;
};

export const createSystemContextValue = (): SystemContext => ({
  MainButton: null,
  BackButton: null,
});

type SystemContextState = [
  SystemContext,
  Dispatch<SetStateAction<SystemContext>>
];
export const systemContext = createContext<SystemContextState>([
  createSystemContextValue(),
  () => {},
]);

export function SystemContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const state = useState(createSystemContextValue);

  const [value] = state;
  const TgMainButton = window.Telegram?.WebApp?.MainButton;
  const TgBackButton = window.Telegram?.WebApp?.BackButton;
  const themeParams = window.Telegram?.WebApp?.themeParams;

  console.log(value);

  useEffect(() => {
    if (value.MainButton) {
      window.Telegram?.WebApp?.MainButton?.show();
    } else {
      window.Telegram?.WebApp?.MainButton?.hide();
      return;
    }

    window.Telegram?.WebApp?.MainButton?.setParams({
      color: value.MainButton?.color || themeParams?.button_color || '#fff',
      is_active: value.MainButton?.isActive,
      is_visible: value.MainButton?.isVisible,
      text_color: value.MainButton?.textColor,
      text: value.MainButton?.text?.toUpperCase(),
    });
    if (value.MainButton?.isProgress) {
      window.Telegram?.WebApp?.MainButton?.showProgress(false);
    } else {
      window.Telegram?.WebApp?.MainButton?.hideProgress();
    }

    if (value.MainButton.onClick) {
      TgMainButton?.onClick(value.MainButton.onClick);
    }
    return () => {
      if (value.MainButton?.onClick) {
        TgMainButton?.offClick(value.MainButton.onClick);
      }
    };
  }, [value.MainButton, themeParams]);

  useEffect(() => {
    if (value.BackButton) {
      window.Telegram?.WebApp?.BackButton?.show();
    } else {
      window.Telegram?.WebApp?.BackButton?.hide();
      return;
    }

    const backOnClick = value.BackButton.onClick;
    if (backOnClick) {
      TgBackButton?.onClick(backOnClick);
      return () => {
        TgBackButton?.offClick(backOnClick);
      };
    }
  }, [value.BackButton, themeParams]);

  return (
    <systemContext.Provider value={state}>{children}</systemContext.Provider>
  );
}
