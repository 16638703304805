import { useEffect, useRef } from 'react';
import { TgAuthData } from 'modules/telegram/core';
import config from 'config';

export interface TelegramLoginButtonProps {
  dataOnauth?: (user: {
    id: number;
    first_name: string;
    auth_date: number;
    hash: string;
    username?: string;
  }) => void;
}

export function TgLoginWidget() {
  const instance = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://telegram.org/js/telegram-widget.js?22`;
    instance.current?.appendChild(script);

    return () => {
      instance.current?.removeChild(script);
    };
  }, []);

  return <div ref={instance} />;
}

export function loginWithTelegram() {
  return new Promise<TgAuthData>((resolve, reject) => {
    window.Telegram?.Login.auth(
      { bot_id: config.telegramBotId, request_access: true },
      (data) => {
        if (!data) {
          reject('Authorization failed');
          return;
        }

        resolve(data);
      }
    );
  });
}
