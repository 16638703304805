import { lazyWithRetry } from 'modules/routing';
import { NotLoggedInRoute } from 'modules/routing/NotLoggedInRoute';

const RequestPasswordReset = lazyWithRetry(() => import('./index'));

export const requestPasswordResetRoutes = [
  {
    path: '/request-password-reset',
    name: 'Password Reset',
    component: RequestPasswordReset,
    route: NotLoggedInRoute,
  },
];
