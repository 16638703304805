import { lazyWithRetry } from 'modules/routing';
import { Route } from 'react-router-dom';

const ContactUs = lazyWithRetry(() => import('./index'));

export const contactUsRoutes = [
  {
    path: '/contact-us',
    name: 'Contact Us',
    component: ContactUs,
    route: Route,
  },
];
