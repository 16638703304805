import { UserV1 } from 'api/user';
import { cookies, AUTH_SESSION_KEY } from './cookieConverter';

export function getUserFromSession(): UserV1 | null {
  const user = cookies.get() as unknown as { mizar_user?: UserV1 };
  if (user?.mizar_user) return user.mizar_user;

  deleteUserCookies();
  return null;
}

export function deleteUserCookies() {
  cookies.remove(AUTH_SESSION_KEY);
}

export function getLocalRefreshToken() {
  const user = getUserFromSession();
  return user?.refresh_token;
}

export function getLocalAccessToken() {
  const user = getUserFromSession();
  return user?.access_token;
}

export function updateLocalAccessToken(token: string) {
  const user = getUserFromSession();
  if (user) {
    user.access_token = token;
    setUserCookie(user);
  }
}

export function setUserCookie(user: UserV1) {
  cookies.set(AUTH_SESSION_KEY, user, {
    SameSite: 'Strict',
    expires: 15, // cookies are set to expire in a day
  });
}
