import React from 'react';
import { ErrorBoundary as SentryErrorBoundary } from '@sentry/react';

type Props = {
  children: React.ReactNode;
  fallback?: React.ReactElement;
};

const ErrorBoundary = ({ children, fallback }: Props) => {
  return (
    <SentryErrorBoundary
      // showDialog
      fallback={({ error, componentStack }) =>
        fallback || (
          <div>
            <h4>You have encountered an error</h4>
            <p>
              To report an issue please{' '}
              <a href="https://discord.gg/gM4mAYMeWG">contact us on Discord</a>
            </p>
            <div>{error.toString()}</div>
            <div>{componentStack}</div>
          </div>
        )
      }
    >
      {children}
    </SentryErrorBoundary>
  );
};

export default ErrorBoundary;
