import { lazyWithRetry, PrivateRoute } from 'modules/routing';
import { Route } from 'react-router-dom';

const BotInvestment = lazyWithRetry(
  () => import('./[strategyType]/[strategyId]/ActivateStrategy')
);

const BotSettingsModal = lazyWithRetry(
  () =>
    import('modules/marketplace-performance-page/modals/bot-settings-wrapper')
);

const Marketplace = lazyWithRetry(() => import('./index'));
const MarketplaceMain = lazyWithRetry(() => import('./indexMain'));
const MarketplacePerformancePage = lazyWithRetry(
  () => import('./[botId]/performance')
);

export const marketplaceRoutes = [
  {
    path: '/dashboard/marketplace',
    name: 'MarketPlace',
    component: Marketplace,
    exact: true,
    route: Route,
  },
  {
    path: '/dashboard/marketplace/main',
    name: 'MarketplaceMain',
    component: MarketplaceMain,
    exact: true,
    route: Route,
  },

  {
    path: '/dashboard/marketplace/strategies',
    name: 'MarketPlace',
    component: Marketplace,
    exact: true,
    route: Route,
  },
  {
    path: '/dashboard/marketplace/:strategyId/activate',
    name: 'BotsListActivation',
    component: BotInvestment,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/marketplace/:botId/performance',
    name: 'MarketplacePerformancePage',
    component: MarketplacePerformancePage,
    route: Route,
    subroutes: [
      {
        path: '/dashboard/marketplace/:botId/performance/:botType/:settingsId/settings',
        name: 'botSettings',
        component: BotSettingsModal,
        route: PrivateRoute,
      },
    ],
  },
  {
    path: '/dashboard/marketplace/strategies/select',
    name: 'MarketPlace',
    component: Marketplace,
    exact: true,
    route: Route,
  },
];
