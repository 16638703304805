import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const ReferralIdObserver = () => {
  const { search } = useLocation();
  useEffect(() => {
    const referrerId = new URLSearchParams(search).get('referrer_id');
    if (referrerId) Cookies.set('referrerId', referrerId, { expires: 30 });
  }, []);
  return null;
};

export default ReferralIdObserver;
