import { Button } from 'modules/shared-components/button/button';
import { ReactComponent as TelegramIcon } from 'assets/img/news/telegram.svg';
import { useLinkTgAccount } from 'modules/telegram/useLinkTgAccount';
import { useUserState } from 'modules/user/UserContext';

export function LinkAccountButton() {
  const { user } = useUserState();
  const { isLoading, loginToTg } = useLinkTgAccount();

  if (!user || ('id' in user && user.telegram_user_id)) {
    return null;
  }

  return (
    <Button
      className="xxs:p-2 xxs:mr-2 xxs:hidden lg:flex"
      loading={isLoading}
      id="telegram"
      variant="primary"
      onClick={() => loginToTg()}
    >
      <TelegramIcon className="w-4 h-4" />
    </Button>
  );
}
