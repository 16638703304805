import config from 'config';
import { User, useUserState } from './UserContext';

export function useUser(): User {
  const user = useUserState().user;
  if (!user) {
    throw new Error('User not found');
  }
  if (!('access_token' in user)) {
    throw new Error('User without 2fa');
  }
  return user;
}

export function useIsAdmin(): boolean {
  return config.adminIds.includes(useUser().id);
}
