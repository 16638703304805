import Cookies from 'js-cookie';
import { createContext, useContext, useState } from 'react';

export interface RedirectContextValues {
  redirectUrl: string | undefined;
  setRedirectUrl: (value: string) => void;
}

export const RedirectContext = createContext<RedirectContextValues>({
  redirectUrl: undefined,
  setRedirectUrl: () => {},
});

const REDIRECT_COOKIE_KEY = 'redirect';

const RedirectContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [redirectUrl, setRedirectUrl] = useState<string | undefined>(
    Cookies.get(REDIRECT_COOKIE_KEY) !== 'undefined'
      ? Cookies.get(REDIRECT_COOKIE_KEY)
      : undefined
  );
  const setNewRedirectUrl = (newRedirectUrl: string | undefined) => {
    if (newRedirectUrl === undefined) Cookies.remove(REDIRECT_COOKIE_KEY);
    if (typeof newRedirectUrl === 'string')
      Cookies.set(REDIRECT_COOKIE_KEY, newRedirectUrl, { expires: 1 });
    setRedirectUrl(newRedirectUrl);
  };
  return (
    <RedirectContext.Provider
      value={{ redirectUrl, setRedirectUrl: setNewRedirectUrl }}
    >
      {children}
    </RedirectContext.Provider>
  );
};

export function useRedirectContext() {
  const context = useContext(RedirectContext);
  if (context === undefined) {
    throw new Error(
      'useRedirectContext must be used within a RedirectContextProvider'
    );
  }
  return context;
}

export default RedirectContextProvider;
