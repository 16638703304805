import { api } from 'helpers/api/apiCore';
import {
  GetStrategiesParams,
  GetStrategiesResponse,
  GetTradersResponse,
  GetTraderResponse,
  GetStrategyTypesResponse,
  GrantAccessToStrategyParams,
  GetStrategyTipsResponse,
  LikesProps,
  EditLikeProps,
  TraderLevelProps,
} from 'api/marketplace';
import { toQueryString } from 'modules/routing/query-string';
import { StrategiesState } from 'modules/marketplace/hooks/useStrategiesState';
import convertObjectToFormData from 'modules/utils/convertObjectToFormData';
import {
  PublishBotParams,
  EditPublishedStrategyParams,
  InvestParams,
  CalculateAllocationProps,
  CalculateAllocationResponse,
  PublishBotResponse,
} from 'api/dca-bot';
import { HeaderInfoResponse } from 'api/marketplace-performance';

const moduleKey = 'marketplace';
export const marketplaceKeys = {
  getStrategies: (params: Partial<GetStrategiesParams & StrategiesState>) => [
    moduleKey,
    params,
  ],
  getUserTraderLevel: () => [moduleKey, 'user-trader-level'],
  getLikes: () => [moduleKey, 'likes'],
  getStrategy: (params: {
    strategy_id: string | number;
    strategy_type: string;
  }) => [moduleKey, params],

  getStrategyV2: (id: string) => [moduleKey, 'v2', id],
  getStrategyGeneralInfo: (params: {
    strategy_id: string;
    strategy_type: string;
  }) => [moduleKey, params, 'general-info'],

  getPerformance: (id: string) => [moduleKey, 'v2', id, 'performance'],

  getStrategyTips: (params: { strategy_id: string; strategy_type: string }) => [
    moduleKey,
    params,
    'tips',
  ],
  getTraders: (params: { name: string }) => [moduleKey, 'traders', params],
  getTrader: (id: string) => [moduleKey, 'traders', id],
  getBotTypes: () => [moduleKey, 'strategy-types'],
  calculateInvestmentAllocation: (params: CalculateAllocationProps) => [
    moduleKey,
    'calculate-investment-allocation',
    params,
  ],
  getAll: () => [moduleKey],
};

export const MarketplaceService = {
  async getStrategies(params: GetStrategiesParams & Partial<StrategiesState>) {
    const response = await api.get<GetStrategiesResponse>(
      `/api/v2/marketplace/strategies?${toQueryString(params)}`
    );
    return response.data;
  },

  async createLikeStrategy(id: string) {
    const response = await api.post<EditLikeProps>(
      `/api/v2/marketplace/strategy/${id}/create-like`
    );
    return response.data;
  },

  async deleteLikeStrategy(id: string) {
    const response = await api.post<EditLikeProps>(
      `/api/v2/marketplace/strategy/${id}/delete-like`
    );
    return response.data;
  },

  async getLikes() {
    const response = await api.get<LikesProps>(
      `/api/v2/marketplace/user/likes`
    );
    return response.data;
  },

  async getTraders(params: { name: string }) {
    const response = await api.get<GetTradersResponse>(
      `/api/v2/marketplace/traders?${toQueryString({
        name__ilike: `%${params.name}%`,
      })}`
    );

    return response.data;
  },
  async getTrader(id: string) {
    const response = await api.get<GetTraderResponse>(
      `/api/v2/marketplace/trader/${id}`
    );
    return response.data;
  },
  async getUserTraderLevel() {
    const response = await api.get<TraderLevelProps>(
      `/api/v2/marketplace/trader/info/me`
    );
    return response.data;
  },
  async getStrategyTypes() {
    const response = await api.get<GetStrategyTypesResponse>(
      `/api/v2/marketplace/strategy-types`
    );
    return response.data;
  },
  async grantAccessToStrategy(params: GrantAccessToStrategyParams) {
    const result = await api.create(
      '/api/v1/marketplace/grant-access-to-strategy',
      convertObjectToFormData(params)
    );
    return result.data;
  },
  getStrategyV2: async (botId: string) => {
    const strategy = await api.get<HeaderInfoResponse>(
      `/api/v2/marketplace/strategy/main/${botId || ''}`
    );
    return strategy.data;
  },
  async getStrategyTips(params: {
    strategy_id: string;
    strategy_type: string;
  }) {
    const response = await api.get<GetStrategyTipsResponse>(
      `/api/v2/marketplace/strategy/main/settings_id/${params.strategy_id}/type/${params.strategy_type}`
    );
    return response.data;
  },
  publish: async (params: PublishBotParams) => {
    const response = await api.create<PublishBotResponse>(
      '/dashboard/api/publish-strategy',
      convertObjectToFormData(params)
    );
    return response.data;
  },
  editPublishedStrategy: async ({
    strategyId,
    ...rest
  }: EditPublishedStrategyParams) => {
    await api.post(
      `/api/v2/marketplace/strategy/${strategyId}/edit-public-strategy`,
      rest
    );
  },

  async invest(params: InvestParams) {
    await api.create(
      '/dashboard/api/associate-capital',
      convertObjectToFormData(params)
    );
  },

  async calculateInvestmentAllocation(params: CalculateAllocationProps) {
    const result = await api.get<CalculateAllocationResponse>(
      '/dashboard/api/advanced-investment-allocation',
      params
    );

    return result.data;
  },

  requestAccess: async (params: {
    settings_id: string;
    strategy_type: string;
    user_name: string;
    message: string | undefined;
  }) => {
    await api.create(
      '/api/v1/marketplace/request-access-to-strategy',
      convertObjectToFormData(params)
    );
  },
};
