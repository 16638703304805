import { useState, useEffect } from 'react';

export enum ScreenSizes {
  Small = '640px',
  Medium = '768px',
  Large = '1024px',
  XLarge = '1280px',
}

function minWidth(size: string) {
  return `(min-width: ${size})`;
}

export const screenGte = {
  small: minWidth(ScreenSizes.Small),
  medium: minWidth(ScreenSizes.Medium),
  large: minWidth(ScreenSizes.Large),
  xLarge: minWidth(ScreenSizes.XLarge),
};

export function useMediaQuery(query: string) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => {
      setMatches(media.matches);
    };
    media.addListener(listener);
    return () => media.removeListener(listener);
  }, [matches, query]);

  return matches;
}
