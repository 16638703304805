import { getAssetsUrl } from '../../../utils/urlUtils';
import ErrorBoundary from '../../../components/error-boundaries';

type AssetIconProps = { className?: string; img: string };

export function StarIcon({
  img,
  ...rest
}: AssetIconProps & React.ComponentProps<'img'>) {
  return (
    <ErrorBoundary>
      <img
        alt={`star icon ${img}`}
        src={getAssetsUrl() + '/static/img/stars/v2/' + img + '.png'}
        {...rest}
      />
    </ErrorBoundary>
  );
}
