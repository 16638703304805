import { useEffect, Suspense } from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { ScrollToTop } from 'modules/routing';
import VerticalLayout from 'modules/layouts/Vertical';
import { authProtectedFlattenRoutes, noLayoutFlattenRoutes } from './index';
import { useTheme } from 'modules/media/theme';
import ReferralIdObserver from 'modules/routing/ReferralIdObserver';
import { UserContextProvider } from 'modules/user/UserContext';
import { useExpandTelegramApp } from 'modules/telegram/core/useExpandTelegramApp';

const SentryRoute = Sentry.withSentryRouting(Route);

const Routes = (props: any) => {
  const loading = () => <div className="" />;
  const theme = useTheme();

  useEffect(() => {
    if (theme !== undefined) {
      document.body.setAttribute('theme', theme);
      if (theme === 'dark') {
        document.body.classList.add('dark');
      } else if (theme === 'light') {
        document.body.classList.remove('dark');
      }
    }
  }, [theme]);

  const modalRoutes = authProtectedFlattenRoutes.reduce((acc, route) => {
    return [
      ...acc,
      ...(route.subroutes
        ?.filter((subroute: any) => subroute.config?.redirect)
        .map((subroute: any) => subroute.path) || []),
    ];
  }, []);

  useExpandTelegramApp();

  return (
    <UserContextProvider>
      <BrowserRouter>
        <ScrollToTop modalRoutes={modalRoutes} />
        <ReferralIdObserver />
        <Switch>
          <SentryRoute
            path={authProtectedFlattenRoutes.map((r: any) => r.path)}
          >
            <VerticalLayout {...props}>
              <Switch>
                {authProtectedFlattenRoutes.map((route) => {
                  if (route.children) {
                    return null;
                  }
                  if (route.subroutes) {
                    return (
                      <route.route key={route.path} path={route.path}>
                        <>
                          <route.component />
                          {route.subroutes.map((subroute: any) => (
                            <subroute.route
                              key={subroute.path}
                              path={subroute.path}
                              exact={subroute.exact}
                            >
                              <subroute.component {...subroute.config} />
                            </subroute.route>
                          ))}
                        </>
                      </route.route>
                    );
                  }
                  return (
                    <route.route
                      key={route.path}
                      path={route.path}
                      exact={route.exact}
                      component={route.component}
                    />
                  );
                })}
              </Switch>
            </VerticalLayout>
          </SentryRoute>

          <SentryRoute path={noLayoutFlattenRoutes.map((r: any) => r.path)}>
            <Suspense fallback={loading()}>
              <Switch>
                {noLayoutFlattenRoutes.map((route) => {
                  return (
                    !route.children && (
                      <route.route
                        key={route.path}
                        path={route.path}
                        roles={route.roles}
                        exact={route.exact}
                        component={route.component}
                      />
                    )
                  );
                })}
              </Switch>
            </Suspense>
          </SentryRoute>
        </Switch>
      </BrowserRouter>
    </UserContextProvider>
  );
};

export default Routes;
