import { Helmet } from 'react-helmet';
import React from 'react';

export const MetaInfo = () => {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Mizar - The Smarter Way to Trade Crypto</title>
      <meta
        name="description"
        content="Develop your trading bots with a few clicks. Copy the best traders and perform as they do. Zero upfront costs and subscription free."
      />
    </Helmet>
  );
};
