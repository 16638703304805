import { lazyWithRetry, PrivateRoute } from 'modules/routing';

const Exchanges = lazyWithRetry(() => import('./index'));
const NewExchange = lazyWithRetry(() => import('./connect'));
const EditExchangeKeys = lazyWithRetry(() => import('./edit'));

export const exchangesRoutes = [
  {
    path: '/dashboard/exchanges/connect',
    name: 'NewExchange',
    component: NewExchange,
    route: PrivateRoute,
  },
  {
    path: '/dashboard/exchanges',
    name: 'Exchanges',
    component: Exchanges,
    route: PrivateRoute,
    exact: true,
    subroutes: [
      {
        path: `/dashboard/exchanges/:keyId/edit`,
        name: 'EditExchangeKeys',
        component: EditExchangeKeys,
        route: PrivateRoute,
        exact: true,
      },
    ],
  },
];
