import { lazyWithRetry } from 'modules/routing';
import { NotLoggedInRoute } from 'modules/routing/NotLoggedInRoute';

const Login = lazyWithRetry(() => import('./index'));

export const loginRoutes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    route: NotLoggedInRoute,
  },
];
