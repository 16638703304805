import ErrorBoundary from 'components/error-boundaries';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { getAssetsUrl } from 'utils/urlUtils';

type Props = {
  className?: string;
  imgName: string;
};

export function ExchangeIcon({ className, imgName }: Props) {
  return (
    <ErrorBoundary>
      <span
        className={classNames(
          className,
          'h-6 w-6 flex-shrink-0 xxs:rounded-sm flex justify-center'
        )}
      >
        <img
          alt={`exchange_logo_${imgName}`}
          src={
            getAssetsUrl() +
            '/static/img/exchanges/' +
            imgName?.toLowerCase() +
            '.svg'
          }
          onError={(e: any) => {
            e.target.onerror = null;
            e.target.src = require('assets/img/cryptocurrencies/icons/generic.png');
          }}
          className="h-6 p-0.5"
        />
      </span>
    </ErrorBoundary>
  );
}

export function ChainsIcon({ className, imgName }: Props) {
  return (
    <ErrorBoundary>
      <img
        alt={`chain_logo_${imgName}`}
        src={
          getAssetsUrl() +
          '/static/img/chains/' +
          imgName?.toLowerCase() +
          '.svg'
        }
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = require('assets/img/cryptocurrencies/icons/generic.png');
        }}
        className={twMerge(
          'h-4 w-4 flex-shrink-0 xxs:rounded-sm flex justify-center',
          className
        )}
      />
    </ErrorBoundary>
  );
}

export function ExchangeIconStretch({
  imgName,
  ...rest
}: Props & React.ComponentProps<'img'>) {
  return (
    <ErrorBoundary>
      <img
        alt={`exchange_logo_${imgName}`}
        src={
          getAssetsUrl() +
          '/static/img/exchanges/' +
          imgName?.toLowerCase() +
          '.svg'
        }
        onError={(e: any) => {
          e.target.onerror = null;
          e.target.src = require('assets/img/cryptocurrencies/icons/generic.png');
        }}
        {...rest}
      />
    </ErrorBoundary>
  );
}
