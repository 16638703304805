import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export enum DexSwitchMode {
  Cex = 'cex',
  Dex = 'dex',
}

type State = {
  mode: DexSwitchMode | undefined;
  setMode: (mode: DexSwitchMode) => void;
};

export const DexSwitchContext = createContext<State>({} as State);

const storageKey = 'exchangeMode';

export default function DexSwitchProvider({
  children,
  defaultMode,
}: PropsWithChildren<{ defaultMode?: DexSwitchMode }>) {
  const [mode, setMode] = useState<DexSwitchMode | undefined>(() => {
    return (
      (window.localStorage.getItem(storageKey) as DexSwitchMode) || defaultMode
    );
  });

  const onSetMode = useCallback(
    (newMode: DexSwitchMode) => {
      if (mode !== newMode) {
        window.localStorage.setItem(storageKey, newMode);
        setMode(newMode);
      }
    },
    [mode, setMode]
  );

  return (
    <DexSwitchContext.Provider
      value={useMemo(() => ({ mode, setMode: onSetMode }), [mode, setMode])}
    >
      {children}
    </DexSwitchContext.Provider>
  );
}

export function useExchangeMode() {
  return useContext(DexSwitchContext);
}
