import { lazyWithRetry } from 'modules/routing';
import CreatePasswordSignUp from './CreatePasswordSignUp';
import { NotLoggedInRoute } from 'modules/routing/NotLoggedInRoute';

const SignUp = lazyWithRetry(() => import('./index'));

export const signUpRoutes = [
  {
    path: '/sign-up/create-password',
    name: 'CreatePasswordSignUp',
    component: CreatePasswordSignUp,
    route: NotLoggedInRoute,
  },
  {
    path: '/sign-up',
    name: 'Sign-up',
    component: SignUp,
    route: NotLoggedInRoute,
  },
];
