export default function convertObjectToFormData(
  object: Record<string, unknown>
): FormData {
  const form = new FormData();
  Object.keys(object).forEach((key) => {
    if (object[key] instanceof File) {
      form.append(key, object[key] as Blob);
    } else {
      form.append(key, String(object[key]));
    }
  });
  return form;
}

export function convertObjectToFormDataWithArray(
  object: Record<string, unknown>
) {
  const form = new FormData();
  Object.entries(object).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item, index) => {
        form.append(`${key}-${index}`, String(item));
      });
      return;
    } else {
      form.append(key, String(object[key]));
    }
  });
  return form;
}
