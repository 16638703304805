import { useMutation } from '@tanstack/react-query';
import UserService from 'api/services/UserService';
import { loginWithTelegram } from 'modules/telegram/LoginWidget';
import { useUserState } from 'modules/user/UserContext';
import { UserV1 } from 'api/user';
import { toQueryObject } from 'modules/routing/query-string';
import { toInitData } from 'modules/telegram/core/toInitData';
import { useSnackBar } from 'modules/layouts/SnackBar/context';
import { formatApiError } from 'helpers/api/apiErrors';

export function useLinkTgAccount() {
  const { user: loggedInUser, updateUser } = useUserState();
  const { addNewMessage } = useSnackBar();
  const { mutate: linkAccount, isLoading: isLinking } = useMutation({
    mutationFn: UserService.linkTelegramAccount,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onSuccess: (_, { initData }) => {
      const data = toQueryObject(initData);
      if (data.id) {
        updateUser({ ...(loggedInUser as UserV1), telegram_user_id: +data.id });
      }
    },
    onError: (error) => {
      addNewMessage({
        message: formatApiError(error),
        title: 'Error',
        type: 'error',
      });
    },
  });

  const { mutate: loginToTg, isLoading } = useMutation({
    mutationFn: loginWithTelegram,
    onSuccess: (user) => {
      linkAccount({
        initData: toInitData(user),
        referralCode: null,
        source: 'web',
        telegramEnvironment: 'prod',
      });
    },
  });

  return {
    loginToTg,
    isLoading: isLoading || isLinking,
  };
}
