import axios, { AxiosError, AxiosResponse } from 'axios';
import * as Sentry from '@sentry/react';
import {
  deleteUserCookies,
  getLocalRefreshToken,
  updateLocalAccessToken,
} from 'modules/user/userCookies';

// content type

export const onFullfilled = (response: AxiosResponse) => {
  return response;
};

const REFRESH_TOKEN_URL = '/api/refresh-token';

const SENTRY_FILTERED_MESSAGES = [
  'Min expected allocation to run the strategy',
  'The minimum base order size for this strategy is',
  'Number of positions must be greater than',
  'Invalid reCAPTCHA',
];

// 1. rejection on normal request
// 2. if it is 401 on normal reuest we should do refresh token call ONCE (now is more) - maybe we shouldnt do refresh token call once is expired
// 3a. success - save the access token ->  you come back to redo the orginal request
// 3b. failure - delete user and push to login
// TODO: FIX token refresh

// If there is a 401 in one api we are logged out immediately so the logic below does not work, when working on this ask to the backend team to reduce the
// backend team to reduce the jwt access token expiration time to 5 sec to try out.
export const onRejected = async (error: AxiosError) => {
  const originalRequest = error.config;
  if (originalRequest.url?.includes(REFRESH_TOKEN_URL)) {
    return Promise.reject(error);
  }

  if (error.response?.status === 401) {
    const refreshToken: string | undefined = getLocalRefreshToken();
    if (refreshToken) {
      try {
        const refreshResponse = await axios
          .create({
            headers: {
              Authorization: `Bearer ${refreshToken}`,
            },
          })
          .post(REFRESH_TOKEN_URL);
        const newToken = refreshResponse.data.access_token;
        updateLocalAccessToken(newToken);
        axios.defaults.headers.Authorization = 'Bearer ' + newToken;
        originalRequest.headers.Authorization = 'Bearer ' + newToken;
      } catch {
        deleteUserCookies();
        window.location.href = window.location.origin + '/login';
        return Promise.reject(error);
      }
      return axios.request(originalRequest);
    }
  }

  const extractErrorMessage = () =>
    `${error.response?.status || ''}: ${error.response?.statusText || ''}`;

  const message =
    (error.response && error.response.data
      ? error.response.data.message
      : error.message) || extractErrorMessage();

  const shouldSendToSentry = !SENTRY_FILTERED_MESSAGES.some((filterMessage) =>
    message.startsWith(filterMessage)
  );

  // Token is not valid anymore so we need to logout user
  if (
    error.response?.status === 422 &&
    error.response.data?.msg === 'Signature verification failed'
  ) {
    deleteUserCookies();
    window.location.href = window.location.origin + '/login';
    return Promise.reject(error);
  }

  if (
    shouldSendToSentry &&
    error.response?.status !== 500 &&
    error.response?.status !== 422
  ) {
    Sentry.captureMessage(message);
  }
  const isMeaningfulErrorsApi = Array.isArray(error.response?.data?.errors);
  if (isMeaningfulErrorsApi) {
    (error as any).errors = error.response?.data?.errors;
    return Promise.reject(error);
  }

  const isDetailedApiError = Array.isArray(error.response?.data);
  if (isDetailedApiError) {
    return Promise.reject(error.response?.data);
  }

  return Promise.reject(message);
};
