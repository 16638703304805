import Big, { BigSource } from 'big.js';

export function toOnchainTokenAmount(amount: BigSource, decimals: number) {
  return BigInt(Big(amount).times(Big(10).pow(decimals)).toFixed());
}

export function fromOnchainTokenAmount(
  amount: BigSource | bigint,
  decimals: number
) {
  return Big(amount.toString()).div(Big(10).pow(decimals));
}

export function toOnchainMzrTokenAmount(amount: BigSource) {
  return BigInt(Big(amount).times(Big(10).pow(18)).toFixed());
}
export function fromOnchainMzrTokenAmount(amount: BigSource | bigint) {
  return Big(amount.toString()).div(Big(10).pow(18));
}

export function fromWei(value: BigSource) {
  return Big(value).div(Big(10).pow(18)).toNumber();
}
export function weiToGwei(value: BigSource) {
  return Big(value).div(Big(10).pow(9)).toNumber();
}
export function etherToGwei(value: BigSource) {
  return Big(value).times(Big(10).pow(9)).toNumber();
}
export function gweiToEther(value: BigSource) {
  return Big(value).div(Big(10).pow(9));
}

export function formatWagmiError(error: Error) {
  if ('reason' in error && typeof error.reason === 'string') {
    return error.reason;
  }
  if ('shortMessage' in error && typeof error.shortMessage === 'string') {
    return error.shortMessage;
  }
  return error.message;
}

export function formatAddress(address: string, size = 4) {
  return address.slice(0, size + 2) + '...' + address.slice(-size);
}
