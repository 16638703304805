import { lazyWithRetry } from 'modules/routing';
import { NotLoggedInRoute } from 'modules/routing/NotLoggedInRoute';

const ThankYouSignup = lazyWithRetry(() => import('./index'));

export const thankYouForSignUpRoutes = [
  {
    path: '/thank-you-for-signing-up',
    name: 'ThankYouSignUp',
    component: ThankYouSignup,
    route: NotLoggedInRoute,
  },
];
