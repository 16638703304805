import { useContext } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';

import { hasActiveJWT } from 'helpers/api/apiCore';
import { RedirectContext } from './RedirectContext';
import { useUserState } from 'modules/user/UserContext';

type Props = {
  roles?: string[];
  component?: any;
} & RouteProps;

export const PrivateRoute = ({
  component: Component,
  children,
  roles,
  ...rest
}: Props) => {
  const { setRedirectUrl } = useContext(RedirectContext);
  const { user } = useUserState();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user || !('access_token' in user) || !hasActiveJWT(user)) {
          setRedirectUrl(props.location.pathname);
          // not logged in so redirect to login page with the return url
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: props.location } }}
            />
          );
        }
        if (children) {
          return children;
        }

        // authorised so return component
        return <Component {...props} />;
      }}
    />
  );
};
