import { api } from 'helpers/api/apiCore';
import { Chains } from 'api/d-wallets';

const SERVICE_KEY = 'blockchain-info';

export const blockchainInfoKeys = {
  getGasInfoWithChain: (chain: Chains) => [SERVICE_KEY, 'gas-price', chain],
};

export const BlockchainInfoService = {
  async getGasInfoWithChain(chain: Chains) {
    const response = await api.get<GasInfo, {}>(
      `/api/v3/blockchain-info/gas-price/${chain}`
    );

    return response.data;
  },
};

export type GasInfo = { gasPrice: number; blockNumber: number };
