import qs, { StringifyOptions } from 'query-string';

export const toQueryString = <
  T extends Record<string, any> = Record<string, any>
>(
  queryOptions: T,
  options?: StringifyOptions
): string => {
  return qs.stringify(queryOptions, options);
};

export const toQueryObject = (
  queryString: string,
  options?: StringifyOptions
) => {
  return qs.parse(queryString, options);
};
