import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import config from './config';

import App from './App';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'modules/media/theme';
import AppMaintenance from './AppMaintenance';

// tagManager is used only in production and we use SENTRY_ENVIRONEMNT to identify
// whether we are in production or staging
if (config.SENTRY_ENVIRONMENT === 'mainnet') {
  const tagManagerArgs = {
    gtmId: 'GTM-KBQ2PV5',
  };
  TagManager.initialize(tagManagerArgs);
}
if (
  process.env.NODE_ENV === 'development' &&
  process.env.REACT_APP_USE_MOCKS === 'true'
) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require('./mocks/browser');
  worker.start();
}

Sentry.init({
  dsn: 'https://3fb189080606487598b043279d480909@o967949.ingest.sentry.io/6156662',
  release: config.VERSION,
  dist: 'production',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  ignoreErrors: [
    // Random plugins/extensions
    'top.GLOBALS',
    'RequestTimeout',
    'ChunkLoadError',
    '403: Forbidden',
    /User already registered/i,
    /Referral code does not exist/i,
    /Network Error/i,
    /Minimum order size allowed is/,
  ],
  denyUrls: [
    // Google Adsense
    /pagead\/js/i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
    // Woopra flakiness
    /eatdifferent\.com\.woopra-ns\.com/i,
    /static\.woopra\.com\/js\/woopra\.js/i,
    // Chrome extensions
    /extensions\//i,
    /extension\//i,
    /^chrome:\/\//i,
    // Other plugins
    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
    /webappstoolbarba\.texthelp\.com\//i,
    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
  ],
  tracesSampleRate: 0,
  environment: config.SENTRY_ENVIRONMENT,
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error &&
      typeof error !== 'string' &&
      typeof error === 'object' &&
      'message' in error &&
      typeof error.message === 'string' &&
      error.message &&
      (error.message.match(/getBoundingClientRect/i) ||
        error.message.match(/removeChild/i) ||
        error.message.match(/Timeout/i))
    ) {
      return null;
    }
    return event;
  },
  enabled: !!config.SENTRY_ENVIRONMENT,
});

ReactDOM.render(
  <ThemeProvider>
    {config.isMaintenance === 'true' ? (
      <>
        <AppMaintenance />
      </>
    ) : (
      <>
        <App />
      </>
    )}
  </ThemeProvider>,
  document.getElementById('root')
);
