import { COLORS_DICTIONARY } from 'modules/shared-components/colorsDictionary';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

type TagProps = {
  color?: keyof typeof COLORS_DICTIONARY;
  condensed?: boolean;
} & React.HTMLAttributes<HTMLSpanElement>;

const Tag: FC<TagProps> = ({
  color = 'gray',
  children,
  condensed = false,
  ...rest
}) => (
  <span
    {...rest}
    className={twMerge(
      `inline-flex items-center xxs:rounded xxs:px-1 py-0.5 font-medium`,
      COLORS_DICTIONARY[color],
      condensed ? 'xxs:text-xs' : 'lg:text-sm',
      rest.className
    )}
  >
    {children}
  </span>
);

export default Tag;
