import { lazyWithRetry } from 'modules/routing';
import { Route } from 'react-router-dom';

const PasswordReset = lazyWithRetry(() => import('./index'));

export const resetPasswordRoutes = [
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: PasswordReset,
    route: Route,
  },
];
