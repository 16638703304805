import { useQuery } from '@tanstack/react-query';
import {
  blockchainInfoKeys,
  BlockchainInfoService,
} from 'api/services/BlockchainInfoService';
import Big from 'big.js';
import { weiToGwei } from 'modules/ethereum/utils';
import { CubeTransparentIcon } from '@heroicons/react/24/outline';
import { PriceService, priceServiceKeys } from 'api/services/PriceService';
import { formatWithoutDecimals } from 'utils/FormatNumber';
import GasIcon from '../../../../assets/img/icons/gas-icon.svg';
import createPersistedState from 'use-persisted-state';
import { chainAsset, chainAssetImg, ChainAssets, Chains } from 'api/d-wallets';
import Button from 'modules/shared-components/button/button';
import { ChainsIcon } from 'modules/shared-components/exchange/exchange-icon';

export const selectedChainGasInfoPersistedState = createPersistedState<Chains>(
  'top-bar-dex-gas-info'
);

export const GasInfo = () => {
  const [selectedChain, setSelectedChain] = selectedChainGasInfoPersistedState(
    Chains.Ethereum
  );

  const refetchTime = selectedChain === Chains.Ethereum ? 15 : 4;

  const { data: gasInfo } = useQuery({
    queryKey: blockchainInfoKeys.getGasInfoWithChain(selectedChain),
    queryFn: () => BlockchainInfoService.getGasInfoWithChain(selectedChain),
    refetchInterval: 1000 * refetchTime,
    staleTime: 1000 * refetchTime,
    cacheTime: 1000 * refetchTime,
    keepPreviousData: true,
  });

  const { data: prices } = useQuery({
    queryKey: priceServiceKeys.getCryptoPrice(),
    queryFn: PriceService.getCryptoPrice,
    refetchInterval: 1000 * refetchTime,
    staleTime: 1000 * refetchTime,
    cacheTime: 1000 * refetchTime,
    keepPreviousData: true,
  });

  const price =
    chainAsset(selectedChain) === ChainAssets.EthereumBase
      ? prices?.eth_price
      : prices?.bnb_price;

  return (
    <Button
      className="m-0 xxs:p-1 hidden lg:block"
      variant="neutral"
      onClick={() => {
        if (selectedChain === Chains.Ethereum) {
          setSelectedChain(Chains.Base);
        } /*else if (selectedChain === Chains.BinanceChain) {
                                    setSelectedChain(Chains.Base);
                                }*/ else {
          setSelectedChain(Chains.Ethereum);
        }
      }}
    >
      <div className="space-x-1 flex items-center">
        <ChainsIcon imgName={selectedChain} />
        <div>|</div>
        <CubeTransparentIcon className="w-4 h-4 hidden lg:inline-block" />
        <div className="font-bold hidden lg:inline-block">
          {gasInfo ? gasInfo.blockNumber : '--'}
        </div>
        <div>|</div>
        <img className="h-4 w-4" alt="gas icon" src={GasIcon} />
        <div className="font-bold">
          {gasInfo?.gasPrice
            ? Big(weiToGwei(gasInfo.gasPrice)).toFixed(4)
            : '--'}
        </div>
        <div className="hidden lg:inline-block">|</div>
        <div className="flex items-center">
          <img
            className="h-4 w-4"
            alt="eth icon"
            src={chainAssetImg(selectedChain)}
          />
          <div className="font-bold">
            ${price ? formatWithoutDecimals(price) : ' --'}
          </div>
        </div>
      </div>
    </Button>
  );
};
