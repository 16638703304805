export function copyContent(setElementCopied, copied_element) {
  try {
    setElementCopied(true);
    setTimeout(function () {
      setElementCopied(false);
    }, 3000);
    return window.navigator.clipboard.writeText(copied_element);
  } catch (error) {
    console.log('Wallet address not found.');
  }
}
